<template>
  <!-- 产品管理 -->
  <div class="productManagement purchase-plan-list">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addAndEdieRow">
          新增产品
        </el-button>
      </div>
      <Table
        :operation-button-width="260"
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { productPage, productDisabled } from '@/api/orderFinancing'
export default {
  components: {
    FormSearch,
    Table,
    Pagination
  },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        source: '',
        status: ''
      },
      formItemArr: [
        { type: 'input', label: '资金方', value: 'funderName', width: '80px' },
        {
          type: 'input',
          label: '担保方',
          value: 'guaranteeCmpName',
          width: '80px'
        },
        {
          type: 'select',
          label: '担保方式',
          pLabel: 'dictName',
          pValue: 'dictId',
          value: 'guaranteeType',
          width: '80px',
          child: this.$store.getters.getDictionaryItem('SCF_PRODUCT_GUARANTEE_TYPE')
        },
        {
          type: 'radio',
          label: '方案来源',
          pLabel: 'dictName',
          pValue: 'dictId',
          value: 'source',
          width: '80px',
          child: [
            { dictName: '后台录入', dictId: 1 },
            { dictName: '资方确定', dictId: 2 }
          ]
        },
        {
          type: 'radio',
          label: '产品状态',
          pLabel: 'dictName',
          pValue: 'dictId',
          value: 'status',
          width: '80px',
          child: [
            { dictName: '待审核', dictId: -1 },
            { dictName: '待生效', dictId: -2 },
            { dictName: '有效', dictId: 1 },
            { dictName: '无效', dictId: 2 }
          ]
        }
      ],
      itemData: [
        { label: '产品编号', prop: 'productNo', width: 150 },
        { label: '资金方名称', prop: 'funderName', width: 180 },
        {
          label: '方案来源',
          prop: 'source',
          width: 180,
          child: [
            { dictName: '后台录入', dictId: 1 },
            { dictName: '资方确定', dictId: 2 }
          ]
        },
        { label: '融资利率', prop: 'loanInterestRate', width: 120 },
        {
          label: '担保方式',
          prop: 'guaranteeType',
          width: 120,
          child: this.$store.getters.getDictionaryItem('SCF_PRODUCT_GUARANTEE_TYPE')
        },
        { label: '担保方', prop: 'guaranteeName', width: 120 },
        { label: '贷款周期', prop: 'maxLoanCycle', width: 100 },
        {
          label: '产品状态',
          prop: 'status',
          width: 120,
          child: [
            { dictName: '待审核', dictId: -1 },
            { dictName: '待生效', dictId: -2 },
            { dictName: '有效', dictId: 1 },
            { dictName: '无效', dictId: 2 }
          ]
        },
        {
          label: '审核状态',
          prop: 'auditStatus',
          width: 120,
          child: this.$store.getters.getDictionaryItem('SCF_PRODUCT_AUDIT_STATUS')
        },
        { label: '创建时间', prop: 'createTime', width: 140 }
      ],
      listData: [],
      operationButton: [
        { num: -2, val: 'status', bType: 'primary', label: '编辑', handleEvent: this.addAndEdieRow },
        {
          num: 1,
          val: 'status',
          bType: 'danger',
          label: '停用',
          handleEvent: this.stopUsingEnableds
        },
        {
          num: -2,
          val: 'status',
          bType: 'primary',
          label: '启用',
          handleEvent: this.stopUsingEnableds
        },
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable },
        { val: 'statusAudit', bType: 'primary', label: '审核', handleEvent: this.viewInfoTable }
      ],
      loading: false,
      total: 0
    }
  },
  methods: {
    // 获取数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      productPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          if (item.status === -1 && item.currentNodeId && item.activiteId) {
            item.statusAudit = true
          } else {
            item.statusAudit = false
          }
          switch (item.loanInterestRateUnit) {
            case 1:
              item.loanInterestRate = item.loanInterestRate + '%'
              break

            case 2:
              item.loanInterestRate = item.loanInterestRate + '%/年'
              break
            case 3:
              item.loanInterestRate = item.loanInterestRate + '元/笔'
              break
          }
          switch (item.maxLoanCycleUnit) {
            case 1:
              item.maxLoanCycle = item.maxLoanCycle ? item.maxLoanCycle + '天' : '-'
              break

            case 2:
              item.maxLoanCycle = item.maxLoanCycle ? item.maxLoanCycle + '月' : '-'
              break
            case 3:
              item.maxLoanCycle = item.maxLoanCycle ? item.maxLoanCycle + '年' : '-'
              break
          }
        })
        this.total = res.data.total
      })
    },
    stopUsingEnableds(row) {
      this.$confirm(`此操作将${row.status === 1 ? '停用' : '启用'}此条数据, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        productDisabled({ funderId: row.funderId, id: row.id, status: row.status === 1 ? 2 : 1 }, () => {
          this.$message.success('成功')
          this.getdata(true)
        })
      }).catch(() => { })
    },
    // 详情
    viewInfoTable(row) {
      this.$store.commit('setResourceByTaskObj', { ...row })
      this.$router.push({
        path: '/orderFinancing/productDetails',
        query: { id: row.id }
      })
    },
    // 添加跟新增的操作
    addAndEdieRow(row) {
      if (row.constructor === Object) {
        this.$router.push({
          path: '/orderFinancing/addProduct',
          query: { id: row.id }
        })
        return
      }
      this.$router.push({
        path: '/orderFinancing/addProduct'
      })
    }
  }
}
</script>
